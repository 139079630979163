import React from "react";
import { Link } from "react-router-dom";

const Sidebar = () => {
  return (
    <div className="bg-gray-800 h-screen p-6 text-white w-64">
      <h3 className="text-2xl font-semibold mb-8">Dashboard</h3>
      <nav>
        <ul>
          <li className="mb-4">
            <Link to="/" className="hover:text-gray-300">
              Banner Image
            </Link>
          </li>
          <li className="mb-4">
            <Link to="/guru-vachan" className="hover:text-gray-300">
              Guru Vachan
            </Link>
          </li>
          <li className="mb-4">
            <Link to="/bhakti-marg" className="hover:text-gray-300">
              Bhakti Marg
            </Link>
          </li>
          <li className="mb-4">
            <Link to="/muhurat" className="hover:text-gray-300">
              Muhurat
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
