import React, { useState, useEffect } from "react";
import {
  getAllGuruVachan,
  createGuruVachan,
  updateGuruVachan,
  deleteGuruVachan,
} from "../api";

const GuruVachan = () => {
  const [entries, setEntries] = useState([]);
  const [personaName, setPersonaName] = useState("");
  const [youtubeIframe, setYoutubeIframe] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchEntries();
  }, []);

  const fetchEntries = async () => {
    setLoading(true);
    try {
      const response = await getAllGuruVachan();
      setEntries(response.data);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateOrUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("personaName", personaName);
    formData.append("youtubeIframe", youtubeIframe);
    if (selectedFile) {
      formData.append("personaImage", selectedFile);
    }

    setLoading(true);
    try {
      if (editingId) {
        await updateGuruVachan(editingId, formData);
        setEditingId(null);
      } else {
        await createGuruVachan(formData);
      }

      setPersonaName("");
      setYoutubeIframe("");
      setSelectedFile(null);
      fetchEntries();
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (entry) => {
    setEditingId(entry._id);
    setPersonaName(entry.personaName);
    setYoutubeIframe(entry.youtubeIframe);
    setSelectedFile(null); // File input will be cleared, user can upload a new file if needed
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await deleteGuruVachan(id);
      fetchEntries();
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-8">
      <h2 className="text-2xl font-semibold mb-6">Guru Vachan</h2>

      {/* Loading Overlay
      {loading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="text-white text-lg">Loading...</div>
        </div>
      )} */}

      <form onSubmit={handleCreateOrUpdate} className="mb-8">
        <div className="mb-4">
          <input
            type="text"
            value={personaName}
            onChange={(e) => setPersonaName(e.target.value)}
            placeholder="Persona Name"
            className="p-2 border rounded w-full"
            required
          />
        </div>
        <div className="mb-4">
          <input
            type="text"
            value={youtubeIframe}
            onChange={(e) => setYoutubeIframe(e.target.value)}
            placeholder="YouTube Iframe"
            className="p-2 border rounded w-full"
            required
          />
        </div>
        <div className="mb-4">
          <input
            type="file"
            onChange={(e) => setSelectedFile(e.target.files[0])}
            className="p-2"
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white py-2 px-4 rounded"
        >
          {/* {editingId ? "Update" : "Create"} */}
          {loading ? "Loading..." : editingId ? "Update" : "Create"}
        </button>
      </form>

      {loading ? (
        <p>Loading entries...</p>
      ) : (
        <ul className="space-x-4 flex gap-5">
          {entries.map((entry) => (
            <li key={entry._id} className="border p-4 rounded shadow h-40">
              <h4 className="font-semibold">{entry.personaName}</h4>
              <img className="h-20 w-20" src={entry.personaImage} alt="jk" />
              <div className="flex mt-2">
                <button
                  onClick={() => handleEdit(entry)}
                  className="mr-4 text-blue-600"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(entry._id)}
                  className="text-red-600"
                >
                  Delete
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default GuruVachan;
