import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import GuruVachan from "./components/GuruVachan";
import BhaktiMarg from "./components/BhaktiMarg";
import Banner from "./components/Banner";
import Muhurat from "./components/Muhurat";

function App() {
  return (
    <Router>
      <div className="flex">
        <Sidebar />
        <div className="flex-grow p-8">
          <Routes>
            <Route path="/guru-vachan" element={<GuruVachan />} />
            <Route path="/bhakti-marg" element={<BhaktiMarg />} />
            <Route path="/" element={<Banner />} />
            <Route path="/muhurat" element={<Muhurat />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
