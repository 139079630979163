import axios from "axios";

const api = axios.create({
  // baseURL: "https://panchang-back.onrender.com/api", // Update this URL as needed
  // baseURL: "http://localhost:5000/api", // Update this URL as needed
  baseURL: "https://api.lalaramnarayan.com/api", // Update this URL as needed
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const getAllGuruVachan = () => api.get("/guru-vachan");
export const createGuruVachan = (formData) =>
  api.post("/guru-vachan", formData);
export const updateGuruVachan = (id, formData) =>
  api.put(`/guru-vachan/${id}`, formData);
export const deleteGuruVachan = (id) => api.delete(`/guru-vachan/${id}`);

export const getAllBhaktiMarg = () => api.get("/bhakti-marg");
export const createBhaktiMarg = (formData) =>
  api.post("/bhakti-marg", formData);
export const updateBhaktiMarg = (id, formData) =>
  api.put(`/bhakti-marg/${id}`, formData);
export const deleteBhaktiMarg = (id) => api.delete(`/bhakti-marg/${id}`);

export const getAllBanner = () => api.get("/banner");
export const createBanner = (formData) => api.post("/banner", formData);
export const updateBanner = (id, formData) =>
  api.put(`/banner/${id}`, formData);
export const deleteBanner = (id) => api.delete(`/banner/${id}`);

export const getAllMuhurats = () => api.get("/muhurat");
export const createMuhurat = (muhurat) =>
  api.post("/muhurat", muhurat, {
    headers: {
      "Content-Type": "application/json", // Ensure it's JSON
    },
  });
export const updateMuhurat = (id, muhurat) =>
  api.put(`/muhurat/${id}`, muhurat);
export const deleteMuhurat = (id) => api.delete(`/muhurat/${id}`);
