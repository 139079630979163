import React, { useState, useEffect } from "react";
import { getAllBanner, createBanner, updateBanner, deleteBanner } from "../api";

const Banner = () => {
  const [entries, setEntries] = useState([]);
  const [personaName, setPersonaName] = useState("");
  const [youtubeIframe, setYoutubeIframe] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchEntries();
  }, []);

  const fetchEntries = async () => {
    setLoading(true);
    const response = await getAllBanner();
    setEntries(response.data);
    setLoading(false);
  };

  const handleCreateOrUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    if (selectedFile) {
      formData.append("bannerImage", selectedFile);
    }

    if (editingId) {
      await updateBanner(editingId, formData);
      setEditingId(null);
    } else {
      await createBanner(formData);
    }

    setPersonaName("");
    setYoutubeIframe("");
    setSelectedFile(null);
    fetchEntries();
    setLoading(false);
  };

  const handleEdit = (entry) => {
    setEditingId(entry._id);
    setPersonaName(entry.personaName);
    setYoutubeIframe(entry.youtubeIframe);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    await deleteBanner(id);
    fetchEntries();
    setLoading(false);
  };

  return (
    <div className="p-8">
      <h2 className="text-2xl font-semibold mb-6">Banner Image </h2>
      <form onSubmit={handleCreateOrUpdate} className="mb-8">
        <div className="mb-4">
          <input
            type="file"
            onChange={(e) => setSelectedFile(e.target.files[0])}
            className="p-2"
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white py-2 px-4 rounded"
          disabled={loading}
        >
          {loading ? "Loading..." : editingId ? "Update" : "Create"}
        </button>
      </form>

      {loading ? (
        <p>Loading entries...</p>
      ) : (
        <ul className="space-y-4 flx gap-5">
          {entries.map((entry) => (
            <li key={entry._id} className="border p-4 rounded shadow h-96">
              <img className="h-[300px]" src={entry.bannerImage} alt="jk" />
              <div className="flex mt-2">
                <button
                  onClick={() => handleEdit(entry)}
                  className="mr-4 text-blue-600"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(entry._id)}
                  className="text-red-600"
                >
                  Delete
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Banner;
