import React, { useState, useEffect } from "react";
import {
  getAllMuhurats,
  createMuhurat,
  updateMuhurat,
  deleteMuhurat,
} from "../api";

const Muhurat = () => {
  const [muhurats, setMuhurats] = useState([]);
  const [muhuratType, setMuhuratType] = useState("");
  const [muhuratDate1, setMuhuratDate1] = useState("");
  const [muhuratDate2, setMuhuratDate2] = useState("");
  const [muhuratDescription, setMuhuratDescription] = useState("");
  const [editingId, setEditingId] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchMuhurats();
  }, []);

  const fetchMuhurats = async () => {
    // setLoading(true);
    const response = await getAllMuhurats();
    setMuhurats(response.data);
    // setLoading(false);
  };

  const handleCreateOrUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("MuhuratType", muhuratType);
    formData.append("muhuratDate1", muhuratDate1);
    formData.append("muhuratDate2", muhuratDate2);
    formData.append("muhuratdescription", muhuratDescription);

    if (editingId) {
      await updateMuhurat(editingId, formData);
      setEditingId(null);
    } else {
      await createMuhurat(formData);
    }

    setMuhuratType("");
    setMuhuratDate1("");
    setMuhuratDate2("");
    setMuhuratDescription("");
    fetchMuhurats();
    setLoading(false);
  };

  const handleEdit = (muhurat) => {
    setEditingId(muhurat._id);
    setMuhuratType(muhurat.MuhuratType);
    setMuhuratDate1(muhurat.muhuratDate1);
    setMuhuratDate2(muhurat.muhuratDate2);
    setMuhuratDescription(muhurat.muhuratdescription);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    await deleteMuhurat(id);
    fetchMuhurats();
    setLoading(false);
  };

  return (
    <div className="p-8">
      <h2 className="text-2xl font-semibold mb-6">Muhurat Management</h2>

      {/* Form for Create/Update */}
      <form onSubmit={handleCreateOrUpdate} className="mb-8">
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium">Muhurat Type</label>
          <select
            value={muhuratType}
            onChange={(e) => setMuhuratType(e.target.value)}
            className="p-2 border rounded w-full"
            required
          >
            <option value="">Select Type</option>
            <option value="vivah muharat">vivah muharat </option>
            <option value="mundan muhurat">mundan muhurat</option>
            <option value="grah pravesh">grah pravesh</option>
            <option value="new business open muhurat">
              new business open muhurat
            </option>
            <option value="vehicle purchase muhurat">
              vehicle purchase muhurat
            </option>
            <option value="namkaran muhurat">namkaran muhurat</option>
            <option value="annaprashashan muhurat">
              annaprashashan muhurat
            </option>
            <option value="property purchase muhurat">
              property purchase muhurat
            </option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium">Start Date</label>
          <input
            type="datetime-local"
            value={muhuratDate1}
            onChange={(e) => setMuhuratDate1(e.target.value)}
            className="p-2 border rounded w-full"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium">End Date</label>
          <input
            type="datetime-local"
            value={muhuratDate2}
            onChange={(e) => setMuhuratDate2(e.target.value)}
            className="p-2 border rounded w-full"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium">Description</label>
          <textarea
            value={muhuratDescription}
            onChange={(e) => setMuhuratDescription(e.target.value)}
            className="p-2 border rounded w-full"
            required
          />
        </div>

        <button
          type="submit"
          className="bg-blue-500 text-white py-2 px-4 rounded"
          disabled={loading}
        >
          {loading ? "Loading..." : editingId ? "Update" : "Create"}
        </button>
      </form>

      {/* List of Muhurats */}
      {loading ? (
        <p>Loading entries...</p>
      ) : (
        <ul className="space-y-4">
          {muhurats.map((muhurat) => (
            <li key={muhurat._id} className="border p-4 rounded shadow">
              <h4 className="font-semibold">{muhurat.MuhuratType}</h4>
              <p>{muhurat.muhuratdescription}</p>
              <p>
                {new Date(muhurat.muhuratDate1).toLocaleString()} -{" "}
                {new Date(muhurat.muhuratDate2).toLocaleString()}
              </p>
              <div className="flex mt-2">
                <button
                  onClick={() => handleEdit(muhurat)}
                  className="mr-4 text-blue-600"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(muhurat._id)}
                  className="text-red-600"
                >
                  Delete
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Muhurat;
